.hide-in-print {
  display: flex;
}

@media print {
  .hide-in-print {
    display: none;
  }
}

.overflow-scroll-unless-print {
  overflow-y: scroll;
}

.overflow-hidden-unless-print {
  overflow-y: hidden;
}

@media print {
  .overflow-scroll-unless-print, .overflow-hidden-unless-print {
    overflow-y: visible;
  }
}
/*# sourceMappingURL=index.fa97b7a4.css.map */
