/* 
    These classes help you hide elements in print mode, and define a print area.
    Used e.g. to help Studio print a single page without the menu and irrelevant
    elements.
*/

.hide-in-print {
    display: flex;
}
@media print {
    .hide-in-print {
        display: none;
    }
}

.overflow-scroll-unless-print {
    overflow-y: scroll;
}

.overflow-hidden-unless-print {
    overflow-y: hidden;
}

@media print {
    .overflow-scroll-unless-print {
        overflow-y: visible;
    }

    .overflow-hidden-unless-print {
        overflow-y: visible;
    }
}
